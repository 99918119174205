html,
body {
    position: fixed;
    overflow: hidden;
}

html,
body,
#root,
#root > div,
.App {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

@media print {
    html,
    body,
    #root,
    #root > div,
    .App {
        position: static;
        overflow: auto;
        height: auto;
    }
}

.leadinModal-content {
    font-family: "Muli";
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    transition: 0.2s linear left;
    /* maybe other vendor-prefixed transitions */
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    transition: 0.2s linear top;
    /* maybe other vendor-prefixed transitions */
}
